/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import styles from "./Create.module.scss";
import { Select, Preloader } from "../../components";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context";
import TopBar from "../../components/shared/Topbar/TopBar";
import { promises as fs } from 'fs';

type Props = {
    initialColor?: string;
    draggedColor?: string;
    // onBase64Update?: (base64: string) => void;
    // getUploadedBase64?: (base64: string) => void;
    // onFileUploaded?: (file: File) => void;
    // onCloseLoader?: (elementId: number) => boolean | undefined;
    // setBase64Manually?: string;
    // playVideoOnHover?: string; // shown only on hover
    // elementId?: number;
    // elementIndex?: number;
    // width?: number | string;
    // height?: number | string;
    // beforeLoadedScreen?: React.ReactNode;
    // format?: string;
    // hoverStyles?: React.CSSProperties;
    // overrideStyles?: React.CSSProperties;
};

const Create: React.FC<Props> = ({ initialColor, draggedColor }) => {
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    // const { t, i18n } = useTranslation();
    // const [totalUsers, setTotalUsers] = useState<number>(0);
    // const [resultUsers, setResultUsers] = useState<number>(0);
    const [selectedType, setSelectedType] = useState<number>(0);
    const fileRef = useRef<HTMLInputElement>(null);
    const initialColorValue = initialColor || "rgba(245,245,245, 0.25)";
    const draggedColorValue = draggedColor || "rgba(220,220,220, 0.8)";
    const [blockBackground, setBlockBackground] = useState<string>(
        initialColor || "rgba(245,245,245, 0.25)"
    );

    const format="application/pdf";

    const types = [
        "އުމްރާނީ ވަސީލަތް",
        "ސަރުކާރުންދޭ ޚިދުމަތް",
        "އުސޫލާއި ސިޔާސަތު",
        "ސަލާމަތާއި ރައްކާތެރިކަން",
        "ތިމާވެށި",
        "ޞިއްޙަތު",
        "ތަޢުލީމު",
        "ވަޒީފާ",
        "އެހެނިހެން",
    ];
    const chat_id = "-4159436692";
    const token = "6486191530:AAFoojKg4-uhY8WV7qxW8IVFwgm1EGt7rBY";
    const telegramMessageLink:string = "https://api.telegram.org/bot6486191530:AAFoojKg4-uhY8WV7qxW8IVFwgm1EGt7rBY/sendMessage?chat_id=-4159436692&text=";


    const [isLoading, setIsLoading] = useState(false);
    // const [base64, setBase64] = useState<string>("");
    const [uploadedFile, setUploadedFile] = useState<{base64?:string, name?:string, size?:number, type?: string}>({});
    const [data, setData] = useState<{type?:string, details?:string, id?:string, name?:string, phone?:string, agreed?:boolean}>({type: types[0]});
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [submissionComplete, setSubmissionComplete] = useState<boolean>(false);

    useEffect(() => {
        if (initialColor) {
            setBlockBackground(initialColor);
        }
    }, [initialColor]);

    const onDragOver = (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const onDragEnter = (e: React.DragEvent) => {
        e.stopPropagation();
        setBlockBackground(draggedColorValue);
    };

    const onDragLeave = (e: React.DragEvent) => {
        e.stopPropagation();
        setBlockBackground(initialColorValue);
    };

    const handleDropEvent = (e: React.DragEvent | React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        e.preventDefault();
        const reader = new FileReader();
        let fileTemp: File | null = null;
        if ("dataTransfer" in e) {
            fileTemp = e.dataTransfer.files[0];
            if (format) {
                const ext = fileTemp?.name?.split(".").pop()?.toLowerCase() || "";
                if (!~format.indexOf(fileTemp.type) && !~format.indexOf(ext)) {
                    if (fileRef.current) {
                        fileRef.current.value = "";
                    }
                    alert("Format is invalid")
                    // toast.show({
                    //     content: "Неверный тип файла",
                    //     type: ToastTypes.failure,
                    // });
                    return;
                }
            }
        } else if (e.target?.files?.length) {
            fileTemp = e.target.files[0];
        }
        if (!fileTemp) {
            return false;
        }

        setIsLoading(true);
        setUploadedFile((prev) => ({...prev, name: fileTemp?.name, size: (fileTemp?.size ?? 0) /1024, type: fileTemp?.type}));
        // console.log(fileTemp);
        if ((fileTemp.size / 1024 / 1024) > 5) {
            alert("File is too big, please upload smaller file < 5mb");
            return;
        }

        reader.readAsDataURL(fileTemp);
        reader.onload = function () {
            // setBlockBackground(`url(${reader.result?.toString()})`);
            let base64String: string = "";
            if (reader.result) {
                base64String = reader.result?.toString();
            }
            setUploadedFile((prev) => ({...prev, base64: base64String}));
            // setBase64(base64String);

            if (fileRef.current) {
                fileRef.current.value = "";
            }
        };
        reader.onerror = function (error) {
            // logDebug("Error: ", error);
        };
    };

    const b64toBlob = (b64Data: string, contentType: string ='', sliceSize: number=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    const handleFormSubmit = async () => {
        setIsSubmitting(true);

        const response = await fetch('https://geolocation-db.com/json/');
        const ipInfo = await response.json();

        // console.log ("ipInfo=", ipInfo);
        // this.setState({ ip: data.IPv4 })
        // alert(this.state.ip)
        // {"country_code":"MV","country_name":"Maldives","city":null,"postal":null,"latitude":3.25,"longitude":73,"IPv4":"69.94.89.51","state":null}


        let message = "";
        message += "ޚިޔާލުގެ ބާވަތް: " + data.type + "\n";
        message += "ތަފުސީލު: " + data.details + "\n";

        message += "އައިޑީ ކާޑް: " + (data.id ?? "-") + "\n";
        message += "ނަން: " + (data.name ?? "-") + "\n";
        message += "ފޯން ނަންބަރ: " + (data.phone ?? "-") + "\n";

        message += "datetime: " + new Date().toISOString() + "\n";
        message += "ip/country: " + ipInfo.IPv4 + " - " + ipInfo.country_name;


        const responseMessage = await fetch(telegramMessageLink + encodeURI(message));

        const respJson = await responseMessage.json();

        // console.log ("responseMessage=", respJson);


        if (uploadedFile.name && uploadedFile.base64)
        {
            console.log(uploadedFile);
            var blob = new Blob([b64toBlob(uploadedFile.base64.split(",")[1])], { type: uploadedFile.type });

            var formData = new FormData();
            formData.append('chat_id', chat_id);
            formData.append('document', blob, uploadedFile.name);
            formData.append('reply_to_message_id', respJson.result?.message_id)
            // formData.append('caption', "new caption");

            var request = new XMLHttpRequest();
            request.open('POST', `https://api.telegram.org/bot${token}/sendDocument`);
            request.send(formData);
        }


        setIsSubmitting(false);
        setSubmissionComplete(true);
    }

    useEffect(() => {
        if (data.agreed && data.type && data.details && data.phone) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [data])

    // useEffect(() => {
    //     setAuth({showBackBtn: true})
    // }, []);

    // useEffect(() => {
    //     if (getUploadedBase64 && base64) {
    //         getUploadedBase64(base64);
    //     }
    // }, [base64]);

    return (
        <div
            className={styles.home}
            // data-theme={theme}
        >
            <TopBar />
            <form
                className="w-full max-h-[90vh] md:max-h-[80vh] max-w-[940px] transform overflow-hidden rounded-2xl bg-white text-right align-middle shadow-xl transition-all flex flex-col min-h-[80vh]"
                id="headlessui-dialog-panel-:re:"
                data-headlessui-state="open"
            >
                <div className="flex flex-col overflow-y-auto pt-14 pb-40 px-4 md:px-20 ">
                    <h3
                        className="flex pb-12 gap-6 text-[26px] lg:text-4xl font-aamu text-[#47034e]"
                        id="headlessui-dialog-title-:rf:"
                        data-headlessui-state="open"
                    >
                        <div className="bg-white p-4 rounded-[20px] shadow-[0px_20px_30px_0px_#C1A04412] -mt-4 w-[62px] h-[62px]">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 28 28"
                                className="h-7 w-7"
                            >
                                <g clipPath="url(#a)">
                                    <path
                                        fill="#C972C0"
                                        fillRule="evenodd"
                                        d="M1.8 20.82.03 26.68a1 1 0 0 0 .2.94c.22.26.57.39.9.33l7.38-1.1A13.99 13.99 0 1 0 13.98 0 13.98 13.98 0 0 0 1.8 20.82Z"
                                        clipRule="evenodd"
                                    ></path>
                                    <g clipPath="url(#b)">
                                        <path
                                            fill="#FFEDCD"
                                            d="M12.73 12.69v-2.8c0-.45.12-.78.36-1 .12-.1.24-.19.4-.26.18-.07.32-.12.49-.12.33 0 .64.12.9.36.27.21.38.55.38 1v2.8h2.87c.17 0 .36.04.55.11.19.05.33.15.45.29.24.26.36.57.36.93 0 .29-.12.6-.36.9-.26.25-.6.37-1 .37h-2.87v2.84c0 .26-.02.45-.1.6-.06.14-.13.26-.28.37-.21.24-.52.36-.88.36a1.31 1.31 0 0 1-1.29-1.36v-2.84H9.87c-.22 0-.36 0-.5-.07l-.29-.12c-.07-.02-.14-.1-.19-.14-.12-.12-.17-.2-.19-.22l-.1-.19-.14-.52c.02-.24.07-.41.1-.5.02-.1.07-.17.12-.22l.16-.21c.24-.22.58-.34 1-.39h2.85l.04.03Z"
                                        ></path>
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="a">
                                        <path fill="#fff" d="M0 0h27.96v27.96H0z"></path>
                                    </clipPath>
                                    <clipPath id="b">
                                        <path fill="#fff" d="M5 5h18v18H5z"></path>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        ޚިޔާލެއް ހުށަހެޅުއްވުމަށް
                    </h3>
                    <input
                        type="hidden"
                        hidden={true}
                        readOnly={false}
                        name="suggestionType"
                        value="އުމްރާނީ ވަސީލަތް"
                        // style="position: fixed; top: 1px; left: 1px; width: 1px; height: 0px; padding: 0px; margin: -1px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); white-space: nowrap; border-width: 0px; display: none;"
                    />

                    {submissionComplete &&  (<div className="mx-auto max-w-7xl items-center justify-center px-5 lg:px-0 text-center bg-white20">
                        <span className="text-[18px] lg:text-[21px] pt-10 pb-2">
                         ޚިޔާލު ފޮނުވިއްޖެ, އަޅުގަނޑުމެންގެ ޓީމްއިން ބެލުމަށްފަހު ޖަވާބު ވަރަށް އަވަހަށް އަރުވާނަން.
                        </span>
                    </div>)}

                    {!submissionComplete &&
                    <>
                        <Select
                            optionsList={types}
                            labelName={"ޚިޔާލުގެ ބާވަތް"}
                            className="text-[18px] lg:text-[21px]"
                            hasHiddenPart={false}
                            defaultSelectedIndex={selectedType}
                            onElementSelected={(i) => {
                                setSelectedType(i);
                                setData((prev) => ({...prev, type: types[i]}));
                            }}
                        />
                        <div className="pt-10">
                            <fieldset>
                                <label
                                    htmlFor="details"
                                    className="text-[18px] lg:text-[21px] pt-10 pb-2"
                                >
                                    ތަފުސީލު
                                </label>
                                <div className="w-full mt-3">
                                    <textarea
                                        id="details"
                                        name="detailsofSuggestion"
                                        placeholder="ކަމުގެ ތަފުސީލު ލިޔުއްވާ"
                                        rows={3}
                                        className="w-full"
                                        onChange={(event) => setData((prev) => ({...prev, details: event.target.value}))}
                                    ></textarea>
                                </div>
                            </fieldset>
                        </div>
                        <div className="text-[18px] lg:text-[21px] pt-10 flex flex-col gap-2 cursor-pointer">
                            <span>ގުޅުންހުރި ލިޔެކިޔުން</span>
                            {!uploadedFile.base64 && (
                                <div
                                    className="border border-dashed border-primary-400 rounded-2xl p-8 text-center text-primary-500  text-[19px] bg-primary-500/[2%] hover:bg-primary-500/[4%] transition-colors "
                                    style={{background: blockBackground}}
                                    role="presentation"
                                    tabIndex={0}
                                    onClick={() => {
                                        fileRef.current?.click();
                                    }}
                                    onDragEnter={onDragEnter}
                                    onDragLeave={onDragLeave}
                                    onDragOver={onDragOver}
                                    onDrop={handleDropEvent}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        className="inline"
                                    >
                                        <path d="M12 5v14M5 12h14"></path>
                                    </svg>{" "}
                                    ފައިލް ސެލެކްޓު ކުރައްވާ{" "}
                                    <span className="text-gray/40 hidden md:inline">
                                        ނުވަތަ ފައިލް މިތަނަށް ޑްރޮޕް ކޮއްލައްވާ!
                                    </span>
                                    <input
                                        name="supportingDocument"
                                        ref={fileRef}
                                        onChange={handleDropEvent}
                                        multiple={true}
                                        type="file"
                                        tabIndex={-1}
                                        style={{ display: "none" }}
                                    />
                                </div>
                            )}

                            {uploadedFile.base64 && <div className="flex gap-3">
                                <button className="flex-none bg-gray/[4%] hover:bg-gray/[8%] w-10 h-10 p-2.5 rounded-md">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 20 20"
                                        className="text-gray/80"
                                        onClick={() => {
                                            if (fileRef.current) {
                                                fileRef.current.value = "";
                                            }
                                            setUploadedFile({});
                                        }}
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M16.07 15.18a.62.62 0 1 1-.89.89L10 10.88l-5.18 5.19a.63.63 0 1 1-.89-.89L9.12 10 3.93 4.82a.63.63 0 0 1 .89-.89L10 9.12l5.18-5.19a.63.63 0 1 1 .89.89L10.88 10l5.19 5.18Z"
                                        ></path>
                                    </svg>
                                </button>
                                <div
                                    dir="ltr"
                                    className="flex-1 flex items-center gap-3 font-inter bg-primary-500/[4%] rounded-md p-2 text-sm font-medium text-left"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                    >
                                        <path
                                            fill="#DE2020"
                                            d="m20 7.7-5.2-5.2a.7.7 0 0 0-.6-.3h-9a1.5 1.5 0 0 0-1.5 1.5v16.6a1.5 1.5 0 0 0 1.5 1.4h3.4a.4.4 0 0 0 .4-.3v-1.9h-.7a.8.8 0 0 1-.8-.7.7.7 0 0 1 .8-.8H9v-1.5h-.7a.8.8 0 0 1-.8-.7.7.7 0 0 1 .8-.8H9v-1.5h-.7a.8.8 0 0 1-.8-.7.7.7 0 0 1 .8-.8H9v-.7a.8.8 0 0 1 .7-.8.8.8 0 0 1 .8.8v.7h.7a.8.8 0 0 1 .8.7.8.8 0 0 1-.8.8h-.7V15h.7a.8.8 0 0 1 .8.7.7.7 0 0 1-.8.8h-.7V18h.7a.8.8 0 0 1 .8.7.8.8 0 0 1-.8.8h-.7v1.9a.4.4 0 0 0 .4.4h7.8a1.5 1.5 0 0 0 1.5-1.5v-12c0-.2 0-.4-.2-.6Zm-5.8.5v-4l4.2 4h-4.1Z"
                                        ></path>
                                    </svg>
                                    <span className="flex-1 text-gray/90">{uploadedFile.name}</span>
                                    <span className="text-gray/30">{uploadedFile.size?.toFixed(2)} kB</span>
                                </div>
                            </div>}
                        </div>
                        <p className="pt-3.5 text-[17px] text-gray/40">
                            ހުރިހާ ލިޔެކިޔުމެއް އެއް ޕީ.ޑީ.އެފް. ގެ ގޮތުގައި އަޕްލޯޑްކުރައްވާ
                        </p>

                        <div className="pt-10">
                            <fieldset>
                                <label
                                    htmlFor="details"
                                    className="text-[18px] lg:text-[21px] pt-10 pb-2"
                                >
                                    އައިޑީ ކާޑް
                                </label>
                                <div className="w-full mt-3">
                                    <input
                                        id="details"
                                        name="detailsofSuggestion"
                                        placeholder="A1234567"
                                        type="text"
                                        className="w-full"
                                        style={{direction:"rtl"}}
                                        onChange={(event) => setData((prev) => ({...prev, id: event.target.value}))}
                                    ></input>
                                </div>
                            </fieldset>
                        </div>
                        <div className="pt-10">
                            <fieldset>
                                <label
                                    htmlFor="details"
                                    className="text-[18px] lg:text-[21px] pt-10 pb-2"
                                >
                                    ނަން
                                </label>
                                <div className="w-full mt-3">
                                    <input
                                        id="details"
                                        name="detailsofSuggestion"
                                        type="text"
                                        className="w-full"
                                        style={{direction:"rtl"}}
                                        onChange={(event) => setData((prev) => ({...prev, name: event.target.value}))}
                                    ></input>
                                </div>
                            </fieldset>
                        </div>
                        <div className="pt-10">
                            <fieldset>
                                <label
                                    htmlFor="details"
                                    className="text-[18px] lg:text-[21px] pt-10 pb-2"
                                >
                                    ފޯން ނަންބަރ
                                </label>
                                <div className="w-full mt-3">
                                    <input
                                        id="details"
                                        name="detailsofSuggestion"
                                        placeholder="7771234"
                                        type="tel"
                                        className="w-full"
                                        style={{direction:"rtl"}}
                                        onChange={(event) => setData((prev) => ({...prev, phone: event.target.value}))}
                                    ></input>
                                </div>
                            </fieldset>
                        </div>

                        <input name="service" type="hidden" value="proposal" />
                        <button type="button" className="text-right">
                            <div className="text-[18px] lg:text-[21px] pt-10 pb-2">އިޤުރާރު</div>
                            <div className="w-full flex gap-4 mt-3">
                                <div className="flex items-center">
                                    <input
                                        id="link-checkbox"
                                        type="checkbox"
                                        value=""
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        onChange={(event) => setData((prev) => ({...prev, agreed: event.target.checked}))}
                                    />
                                    {/* <label htmlFor="link-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">I agree with the <a href="#" className="text-blue-600 dark:text-blue-500 hover:underline">terms and conditions</a>.</label> */}

                                    <label
                                        htmlFor="link-checkbox"
                                        className="flex-1 lg:text-lg text-gray/70"
                                    >
                                        <span>&nbsp;</span> ”ރައްޔިތުންގެ އަޑު“ ޕޯޓަލް ބޭނުންކުރުމުގައި
                                        ޢަމަލުކުރަންވީ އުސޫލުތަކާ އެއްގޮތަށް މި ޕޯޓަލް ބޭނުންކުރުމަށް
                                        އެއްބަސްވަމެވެ.
                                    </label>
                                </div>

                                {/* <div className="flex-none w-[18px] h-[18px] rounded border-2 bg-blue-700 border-blue-700">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="white"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </div>
                                <p className="flex-1 lg:text-lg text-gray/70">
                                    ”ރައްޔިތުންގެ އަޑު“ ޕޯޓަލް ބޭނުންކުރުމުގައި ޢަމަލުކުރަންވީ
                                    އުސޫލުތަކާ އެއްގޮތަށް މި ޕޯޓަލް ބޭނުންކުރުމަށް އެއްބަސްވަމެވެ.
                                </p> */}
                            </div>
                        </button>
                    </>}

                </div>

                {!submissionComplete && <div className="absolute bottom-0 inset-x-0 w-full box-border flex gap-8 justify-center md:justify-end border-t border-gray/10 px-5 pt-5 pb-9 md:pt-6 md:pb-8 md:px-14 bg-white/90 backdrop-blur-lg">
                    <Link
                        className="secondaryBtn w-full max-w-[127px] p-[2px] rounded-[100px] bg-[#BBF1EF] hover:bg-login-button disabled:bg-[#E3F2F6] transition-all"
                        type="reset"
                        to={"/"}
                    >
                        <span className="w-full flex font-waheed text-xl leading-6 items-center justify-center bg-white rounded-[100px] py-3 pr-5 pl-[22px] text-[rgba(11,24,76,0.60)] secondaryBtn-hover:bg-[#E7F9FD] hover:bg-[#E7F9FD] hover:text-[rgba(11,24,76,0.80)] secondaryBtnText secondaryBtn-disabled:text-disabled transition-all">
                            ކެންސަލް
                        </span>
                    </Link>
                    <button
                        className="w-full py-3 pl-[22px] pr-5 md:w-[214px] rounded-full enabled:text-white border-2 border-[rgba(235,250,255,0.91)] bg-primary-button hover:bg-primary-button-hover disabled:border-gray/10 disabled:bg-primary-button-disabled disabled:border-none disabled:text-disabled transition-all"
                        type="button"
                        disabled={!isFormValid}
                        onClick={handleFormSubmit}
                    >
                        {isSubmitting ?
                        <Preloader
                            width="15px"
                            height="20px"
                        /> :
                        "ފޮނުވާ" }
                    </button>
                </div>}
            </form>
        </div>
    );
};

export default Create;
