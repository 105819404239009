/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import styles from "./Home.module.scss";
import mustho from '../../assets/images/mustho copy-03.png'
import dhivehingeMajilis from '../../assets/images/back-01.png'
import { Link } from "react-router-dom";
import { NumberIncrementor } from "../../components";
import TopBar from "../../components/shared/Topbar/TopBar";

const Home: React.FC = () => {
    // const { t, i18n } = useTranslation();
    const maxNumbers = [10,210,560];
    let numbers = [0,0,0];
    // const [numbers, setNumbers] = useState<number[]>([0,0,0]);
    // const [resultUsers, setResultUsers] = useState<number>(0);
    // const [activeUsers, setActiveUsers] = useState<number>(0);
    // const [selectedFilter, setSelectedFilter] = useState<number>(-1);
    // // eslint-disable-next-line @typescript-eslint/ban-types
    // const [chartData, setChartData] = useState<{}[]>();

    // const setUserCounters = (totalUserss: number, result: number, active: number) => {
    //     setTotalUsers(totalUserss);
    //     setResultUsers(result);
    //     setActiveUsers(active);
    // };

    // const changeFilter = (indx: number) => {
    //     setSelectedFilter(indx);
    // };

    // useEffect(() => {
    //     if (chartData && selectedFilter == -1) setSelectedFilter(0);
    // }, [chartData]);
    // const [newsPieces, setNewsPieces] = useState<{
    //     newsList: INewsPiece[];
    //     needPage?: number | null;
    //     pageCount: number;
    //     showBtn: boolean;
    // }>({
    //     newsList: [],
    //     needPage: 1,
    //     pageCount: 2,
    //     showBtn: false,
    // });

    // const [lazyLoadNews, setLazyLoadNews] = useState<boolean>(true);

    // const { responseData: newsListData, error, callApi: getNewsListApi } = getNewsList(newsPieces.needPage ?? 1);

    // useEffect(() => {
    //     if (newsListData) {
    //         setLazyLoadNews(false);
    //         const filteredNewsList = newsListData.results.filter((newElement) => {
    //             return !newsPieces.newsList.some((oldNews) => oldNews.newsId === newElement.newsId);
    //         });
    //         setNewsPieces((prev) => ({
    //             ...prev,
    //             newsList: [...prev?.newsList, ...filteredNewsList],
    //             needPage: newsListData.nextPage,
    //             pageCount: newsListData.pageCount,
    //             showBtn: newsListData.nextPage !== undefined && newsListData.nextPage !== null,
    //         }));
    //     }
    // }, [newsListData]);

    // const getNews = async () => {
    //     if (!newsPieces.needPage) {
    //         return false;
    //     }
    //     setLazyLoadNews(false);
    //     getNewsListApi();
    // };

    // useEffect(() => {
    //     if (lazyLoadNews) {
    //         setLazyLoadNews(false);
    //         getNews();
    //     }
    // }, [lazyLoadNews]);

    // useEffect(() => {
    //     setBreadcrumbs([{ label: t("Главная"), path: "/" }]);
    //     document.title = t("Главная");

    //     return () => {
    //         setBreadcrumbs([]);
    //         document.title = "Atlantis";
    //     };
    // }, [i18n.language]);


    useEffect(() => {
    }, []);

    return (
        <div
            className={styles.home}
            // data-theme={theme}
        >
            <TopBar showBackBtn={false} />
            <div className={styles.home__users}>
                <main className="mx-auto w-ful pb-16 md:pb-24">
                    <div className="mx-auto max-w-7xl items-center justify-center px-5 lg:px-0">
                        <div className="w-full flex flex-col gap-[146px] justify-center items-center">
                            <div className="w-full max-w-[764px] flex flex-col gap-[56px] justify-center items-center">
                                <Link className="w-full flex flex-col gap-8 md:gap-[42px] justify-center items-center" to={"/profile"}>
                                    <img src={mustho}
                                        className="flex-none h-[150px] w-[150px] aspect-square rounded-full"
                                        alt="mustho main photo"/>
                                </Link>

                                <Link
                                    className="bg-login-button p-[2px] rounded-[100px] loginBtn hover:shadow-login-button transition-all"
                                    type="submit"
                                    to="/create"
                                >
                                    <span className="flex flex-row gap-1 font-waheed text-[23px] leading-6 tracking-wide bg-white rounded-[100px] py-3 pr-5 pl-[22px] text-gray\/30 loginBtn-hover:bg-transparent hover:bg-transparent hover:text-[#E5FFFE] loginBtnText transition-all">
                                        ޚިޔާލު ހުށަހަޅުއްވާ
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="m3.22 12.53 6.75 6.75a.75.75 0 0 0 1.06-1.06l-5.47-5.47h14.69a.75.75 0 1 0 0-1.5H5.56l5.47-5.47a.75.75 0 1 0-1.06-1.06l-6.75 6.75a.75.75 0 0 0 0 1.06Z"
                                            ></path>
                                        </svg>
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* <div className="mx-auto flex max-w-7xl pt-[116px] items-center justify-center px-5">
                        <form
                            action="https://citizensvoice.gov.mv/login"
                            method="post"
                            className="mx-auto grid grid-cols-2 gap-3 lg:gap-6 md:gap-8 items-start"
                        >
                            <button
                                className="w-full text-right max-w-[357px] box-border self-stretch rounded-[20px] pt-3 md:pt-5 flex flex-col items-start justify-between group transition-shadow bg-suggestion"
                                type="submit"
                            >
                                <div className="self-stretch md:self-auto md:items-start md:justify-between px-3 md:px-5 pb-[14px] md:pb-9">
                                    <div className="shadow-[0_20px_30px_0_rgba(193,160,68,0.07)] box-border w-[40px] h-[40px] md:w-[60px] md:h-[60px] flex items-center justify-center aspect-square rounded-[13.33px] md:rounded-[20px] bg-white">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 28 28"
                                            className="h-[18px] w-[18px] md:h-7 md:w-7"
                                        >
                                            <g clipPath="url(#a)">
                                                <path
                                                    fill="#C972C0"
                                                    fillRule="evenodd"
                                                    d="M1.8 20.82.03 26.68a1 1 0 0 0 .2.94c.22.26.57.39.9.33l7.38-1.1A13.99 13.99 0 1 0 13.98 0 13.98 13.98 0 0 0 1.8 20.82Z"
                                                    clipRule="evenodd"
                                                ></path>
                                                <g clipPath="url(#b)">
                                                    <path
                                                        fill="#FFEDCD"
                                                        d="M12.73 12.69v-2.8c0-.45.12-.78.36-1 .12-.1.24-.19.4-.26.18-.07.32-.12.49-.12.33 0 .64.12.9.36.27.21.38.55.38 1v2.8h2.87c.17 0 .36.04.55.11.19.05.33.15.45.29.24.26.36.57.36.93 0 .29-.12.6-.36.9-.26.25-.6.37-1 .37h-2.87v2.84c0 .26-.02.45-.1.6-.06.14-.13.26-.28.37-.21.24-.52.36-.88.36a1.31 1.31 0 0 1-1.29-1.36v-2.84H9.87c-.22 0-.36 0-.5-.07l-.29-.12c-.07-.02-.14-.1-.19-.14-.12-.12-.17-.2-.19-.22l-.1-.19-.14-.52c.02-.24.07-.41.1-.5.02-.1.07-.17.12-.22l.16-.21c.24-.22.58-.34 1-.39h2.85l.04.03Z"
                                                    ></path>
                                                </g>
                                            </g>
                                            <defs>
                                                <clipPath id="a">
                                                    <path
                                                        fill="#fff"
                                                        d="M0 0h27.96v27.96H0z"
                                                    ></path>
                                                </clipPath>
                                                <clipPath id="b">
                                                    <path fill="#fff" d="M5 5h18v18H5z"></path>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <p className="pt-5 md:pt-16 pb-4 font-normal font-aamu text-[21px] leading-[32px] md:text-[28px] text-[rgba(71,3,78,1)]">
                                        ޚިޔާލެއް ހުށަހެޅުއްވުމަށް
                                    </p>
                                    <p className="font-normal font-faseyha text-sm leading-[23px] md:text-lg md:leading-relaxed text-[#454545]">
                                        ސަރުކާރުގެ އެއްވެސް ކަމަކާ ގުޅިގެން ރައީސަށް ޚިޔާލެއް
                                        ހުށަހެޅުއްވުމަށް
                                    </p>
                                </div>
                                <div className="w-full box-border mt-5 flex rounded-br-[20px] rounded-bl-[20px] transition-colors pb-[14px] md:pb-[17px] px-3 md:px-5 group-hover:bg-[rgba(201,114,192,1)]">
                                    <div className="w-full box-border pt-[13px] flex transition-border border-t text-[#454545] border-[rgba(71,3,78,0.2)] group-hover:text-[#FFEDCD] group-hover:border-[rgba(201,114,192,1)]">
                                        <span className="w-full font-waheed flex flex-row gap-1 text-base md:text-xl leading-[29px] tracking-[1%] items-center justify-between transition-colors text-[#454545] border-[rgba(71,3,78,0.2)] group-hover:text-[#FFEDCD] group-hover:border-[rgba(201,114,192,1)]">
                                            ހުށަހަޅުއްވާ
                                            <svg
                                                className="inline-block h-6 w-6 "
                                                aria-hidden="true"
                                            >
                                                <use href="/assets/icons-sprite.svg#icon-chevron-left"></use>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </button>

                            <button
                                className="w-full text-right max-w-[357px] box-border self-stretch rounded-[20px] pt-3 md:pt-5 flex flex-col items-start justify-between group transition-shadow bg-complain"
                                type="submit"
                            >
                                <div className="self-stretch md:self-auto md:items-start md:justify-between px-3 md:px-5 pb-[14px] md:pb-9">
                                    <div className="shadow-[0_20px_30px_0_rgba(193,160,68,0.07)] box-border w-[40px] h-[40px] md:w-[60px] md:h-[60px] flex items-center justify-center aspect-square rounded-[13.33px] md:rounded-[20px] bg-white">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 28 28"
                                            className="h-[18px] w-[18px] md:h-7 md:w-7"
                                        >
                                            <g clipPath="url(#a)">
                                                <path
                                                    fill="#FEC00F"
                                                    fillRule="evenodd"
                                                    d="M1.8 20.82.03 26.68a1 1 0 0 0 .2.94c.22.26.57.39.9.33l7.38-1.1A13.99 13.99 0 1 0 13.98 0 13.98 13.98 0 0 0 1.8 20.82Z"
                                                    clipRule="evenodd"
                                                ></path>
                                                <g clipPath="url(#b)">
                                                    <path
                                                        fill="#FFEDCD"
                                                        d="M14 21.19h-.36c-.1 0-.19-.05-.28-.08-.2-.07-.39-.19-.55-.35a2.18 2.18 0 0 1-.4-.55c-.06-.1-.08-.2-.1-.29-.03-.1-.03-.21-.03-.38 0-.48.17-.86.53-1.2.19-.16.38-.28.57-.38.19-.1.4-.12.62-.12.45 0 .84.17 1.17.53.36.3.53.71.53 1.17 0 .43-.17.83-.5 1.17-.34.33-.75.5-1.18.5l-.02-.02Zm-1.5-5.92V8.56c0-.55.14-1 .43-1.32.3-.28.66-.45 1.07-.45.38 0 .72.14 1.05.43.33.29.48.72.48 1.31V15.67c0 .1-.05.2-.1.34-.05.19-.14.38-.28.52-.27.31-.6.48-1.05.48-.46 0-.82-.15-1.15-.46a1.83 1.83 0 0 1-.43-1.28h-.02Z"
                                                    ></path>
                                                </g>
                                            </g>
                                            <defs>
                                                <clipPath id="a">
                                                    <path
                                                        fill="#fff"
                                                        d="M0 0h27.96v27.96H0z"
                                                    ></path>
                                                </clipPath>
                                                <clipPath id="b">
                                                    <path fill="#fff" d="M5 5h18v18H5z"></path>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <p className="pt-5 md:pt-16 pb-4 font-normal font-aamu text-[21px] leading-[32px] md:text-[28px] text-[rgba(67,44,3,1)]">
                                        ޝަކުވާ ހުށަހެޅުއްވުމަށް
                                    </p>
                                    <p className="font-normal font-faseyha text-sm leading-[23px] md:text-lg md:leading-relaxed text-[#454545]">
                                        ސަރުކާރުގެ އެއްވެސް ކަމަކާ ގުޅިގެން ރައީސަށް ޝަކުވާއެއް
                                        ހުށަހެޅުއްވުމަށް
                                    </p>
                                </div>

                                <div className="w-full box-border mt-5 flex rounded-br-[20px] rounded-bl-[20px] transition-colors pb-[14px] md:pb-[17px] px-3 md:px-5 group-hover:bg-[rgba(254,192,15,1)]">
                                    <div className="w-full box-border pt-[13px] flex transition-border border-t text-[#454545] border-[rgba(67,44,3,0.2)] group-hover:text-[#FFFFFF] group-hover:border-[rgba(254,192,15,1)]">
                                        <span className="w-full font-waheed flex flex-row gap-1 text-base md:text-xl leading-[29px] tracking-[1%] items-center justify-between transition-colors text-[#454545] border-[rgba(67,44,3,0.2)] group-hover:text-[#FFFFFF] group-hover:border-[rgba(254,192,15,1)]">
                                            ހުށަހަޅުއްވާ
                                            <svg className="inline-block h-6 w-6 " aria-hidden="true">
                                                <use href="/assets/icons-sprite.svg#icon-chevron-left"></use>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </button>
                        </form>
                    </div> */}

                    <div className="mx-auto w-fit flex gap-6 items-center pt-20 px-5">
                        {/* <img
                            src="./ރައްޔިތުންގެ އަޑު_files/president_dr_muizzu.webp"
                            className="flex-none h-[50px] w-[50px] aspect-square rounded-full"
                            alt=""
                        />
                        <p className="font-faseyha text-lg lg:text-[23px] text-gray/70">
                            ރައީސުލްޖުމްހޫރިއްޔާ ޑޮކްޓަރ މުޙައްމަދު މުޢިއްޒުގެ އިސްނެންގެވުމެއް
                        </p> */}

                        <img src={dhivehingeMajilis}
                            style={{height:"250px"}}
                            className="flex-none aspect-ration rounded-full"
                            alt="mustho main photo"/>
                    </div>



                    <section className="mx-auto max-w-[1360px] pt-[76px] md:pt-[116px]">
                        <div className="rounded-[20px] px-4 pb-16 md:px-10 pt-12 md:pb-20 bg-gray/[2%]">
                            <div
                                id="submissions"
                                className="w-full flex flex-col md:flex-row gap-11 items-center justify-center lg:justify-start"
                            >
                                <p className="not-italic font-waheed text-[28px] md:text-[32px] leading-[30px] text-[#6D7494] tracking-[0.28px] md:tacking-[0.32px] uppercase text-center lg:text-right w-full pb-8 border-b border-gray/10" style={{color: "rgb(27, 27, 27)"}}>
                                    ހުށަހެޅުންތައް
                                </p>
                            </div>
                            <div className="pt-12 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-8"></div>
                            <div className="w-full flex flex-col gap-10 pt-14 pb-10 items-center justify-center">


                    <div className="flex gap-[60px] flex-row">
                        <div className="basis-1/4">
                            <NumberIncrementor endNumber={120} speed={13} />
                            {/* <h1 className="text-[32px] font-bold">{numbers[0]}</h1> */}
                            <h2 className="font-waheed text-[32px] leading-[30px] tracking-[0.32px] uppercase text-center text-light">
                             ހުށަހަޅައިފި
                            </h2>
                        </div>
                        <div className="basis-1/4">
                            <NumberIncrementor endNumber={540} speed={5} />
                            <h2 className="font-waheed text-[32px] leading-[30px] tracking-[0.32px] uppercase text-center text-light">
                             ހިނގަމުންދަނީ
                            </h2>
                        </div>
                        <div className="basis-1/2">
                            <NumberIncrementor endNumber={440} />
                            <h2 className="font-waheed text-[32px] leading-[30px] tracking-[0.32px] uppercase text-center text-light">
                             ނިމިފައި
                            </h2>
                        </div>
                    </div>

                                {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="268"
                                    height="160"
                                    fill="none"
                                >
                                    <rect
                                        width="57.6"
                                        height="57.6"
                                        x="135.5"
                                        fill="#EBEBEB"
                                        fillOpacity=".3"
                                        rx="19.2"
                                    ></rect>
                                    <g clipPath="url(#a)">
                                        <path
                                            fill="#C5C5C5"
                                            fillRule="evenodd"
                                            d="m153.54 35.347-1.68 5.625a.962.962 0 0 0 .192.903.93.93 0 0 0 .864.316l7.085-1.056a13.371 13.371 0 0 0 5.232 1.066c7.411 0 13.421-6.02 13.421-13.42 0-7.402-6-13.422-13.411-13.422-7.412 0-13.421 6.01-13.421 13.421 0 2.381.624 4.627 1.718 6.567Z"
                                            clipRule="evenodd"
                                        ></path>
                                        <g clipPath="url(#b)">
                                            <path
                                                fill="#fff"
                                                d="M165.261 35.7h-.343c-.092 0-.184-.047-.275-.07a1.482 1.482 0 0 1-.527-.343 2.086 2.086 0 0 1-.39-.527c-.046-.092-.069-.184-.092-.275-.023-.092-.023-.207-.023-.367 0-.458.161-.825.505-1.146a2.53 2.53 0 0 1 .55-.367c.183-.091.389-.114.595-.114.436 0 .803.16 1.123.504.344.298.505.688.505 1.123 0 .413-.161.802-.482 1.123-.321.32-.71.481-1.123.481l-.023-.023Zm-1.443-5.684v-6.44c0-.527.137-.963.412-1.26.298-.276.642-.436 1.031-.436.367 0 .688.137 1.009.412.321.275.458.688.458 1.26v6.854c0 .091-.046.183-.092.32a1.08 1.08 0 0 1-.275.504c-.252.298-.572.459-1.008.459-.435 0-.779-.138-1.1-.436-.275-.298-.412-.71-.412-1.237h-.023Z"
                                            ></path>
                                        </g>
                                    </g>
                                    <rect
                                        width="72"
                                        height="72"
                                        x="195.85"
                                        y="60"
                                        fill="#EBEBEB"
                                        fillOpacity=".3"
                                        rx="24"
                                    ></rect>
                                    <g clipPath="url(#c)">
                                        <path
                                            fill="#C5C5C5"
                                            fillRule="evenodd"
                                            d="m218.398 104.184-2.1 7.032c-.12.396-.024.816.24 1.128.264.312.684.468 1.08.396l8.856-1.32a16.716 16.716 0 0 0 6.54 1.332c9.264 0 16.776-7.524 16.776-16.776 0-9.252-7.5-16.776-16.764-16.776s-16.776 7.512-16.776 16.776c0 2.976.78 5.784 2.148 8.208Z"
                                            clipRule="evenodd"
                                        ></path>
                                        <g fill="#fff" clipPath="url(#d)">
                                            <path d="M226.777 98.005c-.258 0-.516-.028-.716-.114a2.114 2.114 0 0 1-.659-.459c-.086-.085-.144-.2-.229-.315-.086-.114-.144-.229-.23-.343-.028-.086-.057-.201-.114-.316-.058-.114-.058-.286-.058-.458 0-.544.201-1.003.602-1.432.401-.43.859-.63 1.404-.63.344 0 .687.085 1.06.286.315.172.544.43.716.745.2.258.286.601.286 1.003 0 .572-.2 1.03-.601 1.432a2.081 2.081 0 0 1-1.461.573v.028ZM233.023 98.005c-.258 0-.516-.028-.716-.114a2.114 2.114 0 0 1-.659-.459c-.086-.085-.143-.2-.229-.315-.086-.114-.144-.229-.23-.343-.028-.086-.057-.201-.114-.316-.057-.114-.057-.286-.057-.458 0-.544.2-1.003.601-1.432.401-.43.86-.63 1.404-.63.344 0 .687.085 1.06.286.315.172.544.43.716.745.201.258.286.601.286 1.003 0 .572-.2 1.03-.601 1.432a2.081 2.081 0 0 1-1.461.573v.028ZM239.239 98.005c-.258 0-.516-.028-.716-.114a2.114 2.114 0 0 1-.659-.459c-.086-.085-.144-.2-.23-.315-.085-.114-.143-.229-.229-.343-.028-.086-.057-.201-.114-.316-.058-.114-.058-.286-.058-.458 0-.544.201-1.003.602-1.432.401-.43.859-.63 1.404-.63.343 0 .687.085 1.06.286.315.172.544.43.716.745.2.258.286.601.286 1.003 0 .572-.2 1.03-.601 1.432a2.081 2.081 0 0 1-1.461.573v.028Z"></path>
                                        </g>
                                    </g>
                                    <rect
                                        width="86.4"
                                        height="86.4"
                                        x="88.45"
                                        y="73"
                                        fill="#EBEBEB"
                                        fillOpacity=".3"
                                        rx="28.8"
                                    ></rect>
                                    <g clipPath="url(#e)">
                                        <path
                                            fill="#C5C5C5"
                                            fillRule="evenodd"
                                            d="m115.508 126.021-2.52 8.438c-.144.475-.029.979.288 1.354.317.374.821.561 1.296.475l10.627-1.584a20.067 20.067 0 0 0 7.848 1.598c11.117 0 20.131-9.028 20.131-20.131 0-11.102-9-20.131-20.116-20.131-11.117 0-20.132 9.014-20.132 20.131 0 3.571.936 6.941 2.578 9.85Z"
                                            clipRule="evenodd"
                                        ></path>
                                        <g clipPath="url(#f)">
                                            <g clipPath="url(#g)">
                                                <path
                                                    fill="#fff"
                                                    d="M126.457 110.699c.068.722.275 1.272.618 1.616.344.344.791.55 1.41.55.412-.069.79-.206 1.1-.447a4.2 4.2 0 0 0 1.031-.894c.378-.412.722-.722.997-.859.309-.241.722-.344 1.135-.344l.446.069.55.172.413.309c.172.138.309.241.378.378.241.31.344.585.344.86 0 .79-.241 1.306-.688 1.581-.447.275-1.203.516-2.2.722-.997.206-1.478.756-1.478 1.65v2.303c0 .688.206 1.203.653 1.582.413.412.928.618 1.513.618a2.9 2.9 0 0 0 .825-.206c.24-.137.481-.275.687-.447.447-.378.653-.894.653-1.581v-.55c1.822-.378 3.094-1.1 3.816-2.2.344-.482.585-1.032.757-1.685.171-.653.24-1.375.24-2.131v-.481c0-.172-.069-.413-.137-.757-.069-.206-.103-.412-.207-.618-.103-.207-.171-.413-.275-.619a7.162 7.162 0 0 0-1.547-1.925c-.309-.241-.653-.447-.996-.653-.344-.207-.757-.344-1.204-.516l-1.306-.309c-.412-.069-.859-.138-1.272-.138-.619 0-1.134.069-1.616.206-.55.138-1.1.344-1.718.619-1.032.516-1.719 1.066-2.097 1.719l-.516.928c-.103.206-.172.378-.172.55l-.137.481-.069.379.069.068Zm3.884 13.476c0 .653.241 1.237.722 1.684.481.482 1.031.722 1.65.722.722 0 1.306-.24 1.753-.687a2.478 2.478 0 0 0 .688-1.719c0-.481-.103-.894-.344-1.238-.241-.343-.516-.653-.825-.859l-.619-.241s-.172 0-.275-.034c-.103 0-.24-.034-.378-.034-.309 0-.584.068-.859.171-.172.069-.31.138-.413.207-.103.068-.24.172-.378.309-.103.103-.206.206-.275.344-.069.137-.172.275-.241.447-.034.103-.068.24-.137.378-.069.137-.069.344-.069.55Z"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                    <rect
                                        width="72"
                                        height="72"
                                        x=".15"
                                        y="34"
                                        fill="#EBEBEB"
                                        fillOpacity=".3"
                                        rx="24"
                                    ></rect>
                                    <g clipPath="url(#h)">
                                        <path
                                            fill="#C5C5C5"
                                            fillRule="evenodd"
                                            d="m22.699 78.185-2.1 7.032c-.12.396-.024.816.24 1.128.264.312.684.468 1.08.396l8.856-1.32a16.716 16.716 0 0 0 6.54 1.332c9.264 0 16.776-7.524 16.776-16.776 0-9.252-7.5-16.776-16.764-16.776S20.55 60.713 20.55 69.977c0 2.976.78 5.784 2.148 8.208Z"
                                            clipRule="evenodd"
                                        ></path>
                                        <g clipPath="url(#i)">
                                            <path
                                                fill="#fff"
                                                d="M35.831 68.426v-3.351c0-.545.143-.946.43-1.204.143-.114.286-.229.487-.315.2-.086.372-.143.573-.143.4 0 .773.143 1.088.43.315.258.459.659.459 1.203v3.352h3.437c.2 0 .43.057.66.143.228.057.4.172.543.344.287.315.43.687.43 1.117 0 .344-.143.716-.43 1.089-.315.286-.716.43-1.203.43h-3.437v3.408c0 .315-.03.545-.115.716a1.287 1.287 0 0 1-.344.459c-.258.286-.63.43-1.06.43-.372 0-.744-.144-1.06-.43-.315-.287-.487-.688-.487-1.203v-3.41h-3.409c-.258 0-.43 0-.601-.085-.172-.086-.258-.115-.344-.144-.086-.028-.172-.114-.23-.171-.142-.144-.2-.23-.228-.258l-.115-.23-.172-.63c.029-.286.086-.487.115-.601a.555.555 0 0 1 .143-.258c.057-.057.115-.172.2-.258.287-.258.688-.401 1.204-.458h3.409l.057.028Z"
                                            ></path>
                                        </g>
                                    </g>
                                    <defs>
                                        <clipPath id="a">
                                            <path
                                                fill="#fff"
                                                d="M151.82 15.36h26.842V42.2H151.82z"
                                            ></path>
                                        </clipPath>
                                        <clipPath id="b">
                                            <path
                                                fill="#fff"
                                                d="M156.621 20.16h17.28v17.28h-17.28z"
                                            ></path>
                                        </clipPath>
                                        <clipPath id="c">
                                            <path
                                                fill="#fff"
                                                d="M216.25 79.2h33.552v33.552H216.25z"
                                            ></path>
                                        </clipPath>
                                        <clipPath id="d">
                                            <path
                                                fill="#fff"
                                                d="M222.25 85.2h21.6v21.6h-21.6z"
                                            ></path>
                                        </clipPath>
                                        <clipPath id="e">
                                            <path
                                                fill="#fff"
                                                d="M112.93 96.04h40.262v40.262H112.93z"
                                            ></path>
                                        </clipPath>
                                        <clipPath id="f">
                                            <path
                                                fill="#fff"
                                                d="M146.05 103.24h-25.92v25.92h25.92z"
                                            ></path>
                                        </clipPath>
                                        <clipPath id="g">
                                            <path
                                                fill="#fff"
                                                d="M146.05 103.24h-25.92v25.92h25.92z"
                                            ></path>
                                        </clipPath>
                                        <clipPath id="h">
                                            <path
                                                fill="#fff"
                                                d="M20.55 53.2h33.552v33.552H20.55z"
                                            ></path>
                                        </clipPath>
                                        <clipPath id="i">
                                            <path
                                                fill="#fff"
                                                d="M26.55 59.201h21.6v21.6h-21.6z"
                                            ></path>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <div className="w-full max-w-[408px] flex flex-col items-center justify-center gap-4">
                                    <h2 className="font-waheed text-[32px] leading-[30px] tracking-[0.32px] uppercase text-center text-light-gray">
                                        އަދި އެއްވެސް ހުށަހެޅުއްވުމެއް ނެތް!
                                    </h2>
                                    <p className="font-faseyha text-lg font-normal leading-[30px] text-center text-light-gray">
                                        މެސެޖެއް ހުށަހެޅުއްވުމަށްޓަކައި މަތީގައިވާ އިޚުތިޔާރުތަކުން
                                        އެންމެ ގުޅުންހުރި ބާވަތް ޚިޔާރު ކުރައްވާ.
                                    </p>
                                </div> */}
                            </div>
                        </div>
                    </section>
                </main>

                {/*
                <Card
                    headerText={t("Пользователи")}
                    headerIcon={homeIcons.user}
                    headerMainElement={
                        <div className={styles["home__users-active"]}>
                            <p>{t("На странице {{resultUsers}}, всего {{totalUsers}}", { resultUsers, totalUsers })}</p>
                            <UsersOnline users={activeUsers} />
                        </div>
                    }
                    body={<UsersBody setUserCounters={setUserCounters} />}
                /> */}
            </div>
        </div>
    );
};

export default Home;
