import React, { useContext, useEffect, useRef, useState } from "react";
import { Routes, Route, useLocation, Link } from "react-router-dom";
import "./App.css";
import { Create, Home, NotFound, Profile } from "./pages";
import { AuthContext } from "./context";

function App() {

    // const showBackButton = () => {
    //     return window.location.href.indexOf("/profile") >= 0 || window.location.href.indexOf("/create") >= 0;
    // }

    // useEffect(() => {
    //     // setShowBackButton(false);

    //     // if (window.location.href.indexOf("/profile") >= 0 || window.location.href.indexOf("/create") >= 0) {
    //     //     setShowBackButton(true);
    //     // }
    //     console.log(">> setShowBackButton");
    // }, []);

    return (
        <div className="App">

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/create" element={<Create />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            {/*
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
        </div>
    );
}

export default App;
